import { useContext } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createHeadingNode, HeadingTagType } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import { $createParagraphNode, $getSelection, $isRangeSelection } from 'lexical';

import { Dropdown, DropdownContext } from '@admin/molecules/RichEditor/Editor/atoms';
import { ToolbarButton, ToolbarList } from '@admin/molecules/RichEditor/Editor/plugins/ToolbarPlugin/atoms';
import { BlockType, BlockTypeContext } from '@admin/molecules/RichEditor/Editor/providers/BlockTypeProvider';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon';
import { useTranslation } from '@pxr/i18n';

import { options } from './helpers';

import styles from './HeadingsSelector.module.scss';

export const HeadingsSelector = () => {
    const [editor] = useLexicalComposerContext();
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const { blockType } = useContext(BlockTypeContext);
    const { close, toggle } = useContext(DropdownContext);

    const formatHeading = (format: HeadingTagType | BlockType.paragraph) => {
        editor.update(() => {
            const selection = $getSelection();

            if (!$isRangeSelection(selection)) {
                return;
            }

            if (format === BlockType.paragraph || blockType === format) {
                $wrapNodes(selection, () => $createParagraphNode());

                return;
            }

            $wrapNodes(selection, () => $createHeadingNode(format as HeadingTagType));
        });

        close();
    };

    const current = options.find(({ value }) => value === blockType);

    return (
        <div className={styles.HeadingsSelector}>
            <ToolbarButton
                aria-label={__translate('select_heading')}
                className={styles.toolbar}
                onClick={toggle}
            >
                {__translate(current?.title || BlockType.paragraph)}
                <Icon.chevronDown />
            </ToolbarButton>
            <Dropdown>
                <ToolbarList>
                    {options?.map(({ title, value }) => (
                        <ToolbarButton
                            filled={blockType === value}
                            key={value}
                            className={styles[value]}
                            onClick={() => formatHeading(value as HeadingTagType)}
                        >
                            {__translate(title)}
                        </ToolbarButton>
                    ))}
                </ToolbarList>
            </Dropdown>
        </div>
    );
};
