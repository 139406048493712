import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@pxr/i18n';

import styles from './Placeholder.module.scss';

export const Placeholder = () => {
    const __translate = useTranslation(TranslationKey.richEditor).t;

    return <div className={styles.Placeholder}>{__translate('placeholder')}</div>;
};
