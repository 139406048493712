import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
    $getSelection,
    $isRangeSelection,
    COMMAND_PRIORITY_LOW,
    FORMAT_TEXT_COMMAND,
    SELECTION_CHANGE_COMMAND,
} from 'lexical';

import { ToolbarButton } from '@admin/molecules/RichEditor/Editor/plugins/ToolbarPlugin/atoms';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon';
import { useTranslation } from '@pxr/i18n';

import { TextFormatType } from '.';

interface Props {
    format: TextFormatType;
}

const icons: {
    bold: ReactNode;
    italic: ReactNode;
    underline: ReactNode;
    code: ReactNode;
} = {
    bold: <Icon.typeBold />,
    italic: <Icon.typeItalic />,
    underline: <Icon.typeUnderline />,
    code: <Icon.code />,
};

export const TextFormat = ({ format }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;
    const [isFormat, setIsFormat] = useState(false);
    const [editor] = useLexicalComposerContext();

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            setIsFormat(selection.hasFormat(format));
        }
    }, [format]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, _newEditor) => {
                    updateToolbar();
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [editor, updateToolbar]);

    const icon = icons[format];

    return (
        <ToolbarButton
            aria-label={__translate(`format_${format}`)}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, format)}
            filled={isFormat}
        >
            {icon}
        </ToolbarButton>
    );
};
