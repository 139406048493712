import { useCallback, useContext, useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createQuoteNode, $isQuoteNode } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import { mergeRegister } from '@lexical/utils';
import {
    $createParagraphNode,
    $getSelection,
    $isRangeSelection,
    COMMAND_PRIORITY_LOW,
    SELECTION_CHANGE_COMMAND,
} from 'lexical';

import { ToolbarButton } from '@admin/molecules/RichEditor/Editor/plugins/ToolbarPlugin/atoms';
import { BlockType, BlockTypeContext } from '@admin/molecules/RichEditor/Editor/providers/BlockTypeProvider';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon';
import { useTranslation } from '@pxr/i18n';

export const Quote = () => {
    const __translate = useTranslation(TranslationKey.richEditor).t;
    const { blockType } = useContext(BlockTypeContext);
    const [editor] = useLexicalComposerContext();

    const [isQuote, setIsQuote] = useState<boolean>(false);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();

            setIsQuote($isQuoteNode(element));
        }
    }, [setIsQuote]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, _newEditor) => {
                    updateToolbar();
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [editor, updateToolbar]);

    const formatQuote = () => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const createNode = blockType === BlockType.quote ? $createParagraphNode : $createQuoteNode;

                $wrapNodes(selection, createNode);
            }
        });
    };

    return (
        <ToolbarButton filled={isQuote} aria-label={__translate('quote')} onClick={formatQuote}>
            <Icon.textQuote />
        </ToolbarButton>
    );
};
